import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Menu from '../Menu'
import MenuTable from '../MenuTable'
import { formatDatoCMSData } from '../../services/datoCMS.service'

export default function OtherDrinksPage(props) {
  const query = useStaticQuery(graphql`
  query otherDrinksMenuQuery {
    datoCmsNotCoffeeMenu {
      teaLattes {
        name
        description
        location {
          bryant
          downtown
        }
        sizes
      }
      tea {
        name
        description
        location {
          bryant
          downtown
        }
        price
      }
      drinks {
        name
        description
        location {
          bryant
          downtown
        }
        sizes
      }
    }
  }`);

  const otherDrinksData = formatDatoCMSData(query);

  return (
    <div id="otherdrinksmenu" className="wrapper style1">
      {/* <span className="image fit main bottom">
              <img src={backgroundImage} alt="" />
            </span> */}
      <div className="container">
        <Menu>
          <header className="major">
            <h2>Not Coffee</h2>
          </header>
          <div className="box alt">
            <div className="row">
              <div className="col-6 col-12-xsmall">
                <h3>Tea</h3>
                <p>(Availabile hot or iced)</p>
                <MenuTable data={otherDrinksData.tea} location={props.location} />
              </div>
              <div className="col-6 col-12-xsmall">
                <h3>Drinks</h3>
                <MenuTable data={otherDrinksData.drinks} location={props.location} />
                <h3>Tea Lattes</h3>
                <MenuTable data={otherDrinksData.teaLattes} location={props.location} />
              </div>
            </div>
          </div>
        </Menu>
      </div>
    </div>
  )
}
