// NOTE TO THE FUTURE: Do not try and move GraphQL queries to this module. It doesn't work with Gatsby
// due to the way DatoCMS data is loaded at build time and curried into Gatsby's GraphQL implementation.
// Trust me, it doesn't work.

// This method is used by various pages and components to format our DatoCMS data into something usable
// Do this instead of changing all our code in case we change data providers in the future.
export function formatDatoCMSData(datoData) {
  let data = {};

  for (const key in datoData) {
    if (Object.hasOwnProperty.call(datoData, key)) {
      let value = datoData[key];

      // If our data starts with 'datoCMS' or 'allDatoCMS' and is an object, strip that level out - we don't ever want it
      if ((key.substring(0, 7) === 'datoCms' || key.substring(0, 10) === 'allDatoCms') && typeof value === 'object') {
        return formatDatoCMSData(value);
      }

      // Convert location into a simple array of strings
      if (key === 'location') {
        let newValue = [];
        const locationKeys = (value && value[0] ? Object.keys(value[0]) : []);

        for (const locationKey of locationKeys) {
          if (Object.hasOwnProperty.call(value[0], locationKey)) {
            const locationValue = value[0][locationKey] ?? false;

            if (locationValue) {
              newValue.push(locationKey);
            }
          }
        }

        // Save the new value to our data array. It won't mess w/ our for loop.
        data['locations'] = newValue;

        // Clean up the old value; we don't need it
        delete data.location;
      } else if (key === 'sizes') {
        // Size / Price data needs to be converted into the format our pages expect
        // Our value is a JSON string, complete with \\n "line feeds". We need to parse that into real JSON to work with the data
        const sizeJson = JSON.parse(value);

        for (const columnData of sizeJson.data) {
          // NOTE: These are capitalized in the DatoCMS schema!
          data[columnData.Size] = columnData.Price;
        }

        // Clean up the old value; we don't need it
        delete data.sizes;
      } else if (Array.isArray(value)) {
        // If we were given an array, we want to use recursion to dive into the children in case we need to parse them out (we probably do)
        let newValue = [];

        for (let i = 0; i < value.length; i++) {
          let v = formatDatoCMSData(value[i]);
          newValue.push(v);
        }

        data[key] = newValue;
      } else {
        data[key] = value;
      }
    }
  }

  return data;
}
