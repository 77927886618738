import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { formatDatoCMSData } from '../../services/datoCMS.service'
import { locationHasMenuItem } from '../../services/location.service'
import { locationData } from '../../assets/locationData'

// NOTE: This is clicky clicky menu, not drinky drinky menu
export default function LocationMenu(props) {
  const query = useStaticQuery(graphql`
  query locationMenuQuery {
    datoCmsArtist {
      location {
        bryant
        downtown
      }
    }
    datoCmsWineMenu {
      white {
        location {
          bryant
          downtown
        }
      }
      red {
        location {
          bryant
          downtown
        }
      }
      sparkling {
        location {
          bryant
          downtown
        }
      }
    }
    datoCmsBeerMenu {
      canned {
        location {
          bryant
          downtown
        }
      }
      tap {
        location {
          bryant
          downtown
        }
      }
    }
    datoCmsCoffeeMenu {
      dripCoffee {
        location {
          bryant
          downtown
        }
      }
      espresso {
        location {
          bryant
          downtown
        }
      }
      flavors {
        location {
          bryant
          downtown
        }
      }
      lattes {
        location {
          bryant
          downtown
        }
      }
      milkAlternatives {
        location {
          bryant
          downtown
        }
      }
      regularCoffee {
        location {
          bryant
          downtown
        }
      }
    }
    datoCmsGelatoMenu {
      gelato {
        location {
          bryant
          downtown
        }
      }
      nonDairy {
        location {
          bryant
          downtown
        }
      }
      servingSizes {
        location {
          bryant
          downtown
        }
      }
    }
    datoCmsNotCoffeeMenu {
      drinks {
        location {
          bryant
          downtown
        }
      }
      tea {
        location {
          bryant
          downtown
        }
      }
      teaLattes {
        location {
          bryant
          downtown
        }
      }
    }
    datoCmsPrivateEvent {
      location {
        bryant
        downtown
      }
    }
    datoCmsSnacksPastryMenu {
      pastriesDesserts {
        location {
          bryant
          downtown
        }
      }
      snacks {
        location {
          bryant
          downtown
        }
      }
    }
  }`);

  const artistData = formatDatoCMSData(query.datoCmsArtist);
  const wineData = formatDatoCMSData(query.datoCmsWineMenu);
  const beerData = formatDatoCMSData(query.datoCmsBeerMenu);
  const coffeeData = formatDatoCMSData(query.datoCmsCoffeeMenu);
  const gelatoData = formatDatoCMSData(query.datoCmsGelatoMenu);
  const notCoffeeData = formatDatoCMSData(query.datoCmsNotCoffeeMenu);
  const eventsData = formatDatoCMSData(query.datoCmsPrivateEvent);
  const foodData = formatDatoCMSData(query.datoCmsSnacksPastryMenu);

  const hasArtistData = locationHasMenuItem(props.location, artistData);
  const hasWineData = locationHasMenuItem(props.location, wineData);
  const hasBeerData = locationHasMenuItem(props.location, beerData);
  const hasCoffeeData = locationHasMenuItem(props.location, coffeeData);
  const hasGelatoData = locationHasMenuItem(props.location, gelatoData);
  const hasNotCoffeeData = locationHasMenuItem(props.location, notCoffeeData);
  const hasEventsData = locationHasMenuItem(props.location, eventsData);
  const hasFoodData = locationHasMenuItem(props.location, foodData);

  const location = locationData.getLocationData(props.location);

  const pages = {
    coffee: 'Coffee',
    wine: 'Wine',
    beer: 'Beer',
    liquor: 'Liquor',
    otherdrinks: "Not Coffee",
    food: 'Food & Pastries',
    gelato: 'Gelato',
    specials: 'Specials',
    events: 'Private Events',
    artists: 'Artists'
  };

  const hasMenuItems = {
    coffee: hasCoffeeData,
    wine: hasWineData,
    beer: hasBeerData,
    liquor: false,
    otherdrinks: hasNotCoffeeData,
    food: hasFoodData,
    gelato: hasGelatoData,
    specials: false,
    events: hasEventsData,
    artists: hasArtistData,
  };

  const items = Object.keys(pages).map((key) => {
    const label = pages[key];

    // Check to see if this key has menu data. If not, don't show anything for the menu here.
    if (!hasMenuItems[key]) {
        return null;
    }

    const className = (props.currentPage === key ? 'active' : null);

    const href = "/" + props.location + "/" + key;

    return (<li key={key} className={className}><Link to={href}>{label}</Link></li>);
  });

  // const hoursClassName = (props.currentPage === 'props.location' ? 'active' : null);

  return (
    <div id="locationmenu">
      <h2><Link to={"/" + props.location}>{location.name}</Link></h2>
      {items[0] &&
        <React.Fragment>
          <ul>
            {items}
          </ul>
        </React.Fragment>
      }
    </div>
  )
}
