import React from 'react'
import { Helmet } from 'react-helmet'
import { locationData } from '../assets/locationData.js'

// Friendly wrapper for Helmet data, since 99% of it is the same across pages anyway
export default function EllisHelmet(props)
{
	const company = 'Ellis Island Coffee and Wine Lounge';
	const url = 'https://ellisislandcoffee.com/' + (props.location ? props.location + '/' : '') + props.page;
	const logo_url = 'https://ellisislandcoffee.com/images/social_embed.jpg';

	const location = (props.location ? locationData.getLocationData(props.location) : null);
	const title = (props.title ? props.title + ' - ' : '') + (location !== null ? location.name + ' - ' : '')  + company;

	return (
		<Helmet
			htmlAttributes={{ lang: 'en' }}
			title={title}
			meta={[
				{ name: 'title', content: title },
				{ name: 'description', content: company },
				{ name: 'keywords', content: 'ellis,island,ellis island,coffee,wine,coffee and wine,coffee & wine,lounge,pastries,gelato,la baugette' },
				{ name: 'og:type', content: 'website' },
				{ name: 'og:url', content: url },
				{ name: 'og:title', content: title },
				{ name: 'og:description', content: company },
				{ name: 'og:image', content: logo_url },
				{ name: 'twitter:card', content: 'summary_large_image' },
				{ name: 'twitter:url', content: url },
				{ name: 'twitter:title', content: title },
				{ name: 'twitter:description', content: company },
				{ name: 'twitter:image', content: logo_url },
			]}
		></Helmet>
	);
}
