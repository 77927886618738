import React from 'react'

export default function MenuTable(props) {
  if (props.data == null)
    return null;

  let headings = [];
  let hasNameColumn = false;
  let hasPriceColumn = false;

  const pricePrecision = 1;

  // Process every row in the table
  for (let row of props.data) {
    // If we are requesting location-specific menu items, check for that here
    if (props.location) {
      // If we got here, our current location is NOT selected for this menu item! Skip it!
      if (row.locations != null && !row.locations.includes(props.location))
        continue;
    }

    // Process every cell in the row, pulling out header information
    // NOTE: Use for-in instead of for-of here because these are JSON objects!
    for (let key in row) {
      // These columns gets special treatment below, so no need to add them to our headers
      if (key === "name")
        hasNameColumn = true;
      else if (key === "price")
        hasPriceColumn = true;
      else if (key === "description")
        ; // Do nothing, for now
      else if (key === "locations")
        ; // Do nothing, for now
      else
        headings.push(key);
    }
  }

  headings = [...new Set(headings)];	// Uniquify headings

  // DO NOT DO THIS! It messes up preferred order of Glass, Bottle on the Wine page!
  // If we ever do need to sort again, we're going to need to do something more complicated...
  //headings = headings.sort();	// Sort headings after it has been uniquified

  let thead = null;
  if (headings.length) {
    /* eslint-disable */
    let headingRows = [];

    if (hasNameColumn)
      headingRows.push(<th key="name"></th>);

    for (let heading of headings)
      headingRows.push(<th key={heading}>{capitalize(heading)}</th>);

    if (hasPriceColumn)
      headingRows.push(<th key="price"></th>);

    thead = <thead><tr>{headingRows}</tr></thead>;
  }

  // Now run through the rows *again*, but this time we'll pull out data in the order we want it from headers
  let rows = [];
  const len = props.data.length;
  var rowClassName = null;
  const colSpan = len + (hasNameColumn ? 1 : 0) + (hasPriceColumn ? 1 : 0);
  for (let i = 0; i < len; i++) {
    let row = props.data[i];

    // If we are requesting location-specific menu items, check for that here
    if (props.location) {
      // If we got here, our current location is NOT selected for this menu item! Skip it!
      if (row.locations != null && !row.locations.includes(props.location))
        continue;
    }

    rowClassName = (rowClassName === 'odd' ? "even" : "odd");
    let cells = [];

    if (hasNameColumn || row.name != null)
      cells.push(<td key="name" className="name">{row.name}</td>);

    for (let key of headings) {
      if (row[key] != null && row[key] != '' && !isNaN(row[key]) && row[key] % 1 == 0) {
        row[key] = Number.parseFloat(row[key]).toFixed(pricePrecision);
      }

      cells.push(<td key={key} className={key + " price"}>{row[key]}</td>);
    }

    if (hasPriceColumn || row.price != null) {
      if (row.price != null && row.price != '' && !isNaN(row.price) && row.price % 1 == 0) {
        row.price = Number.parseFloat(row.price).toFixed(pricePrecision);
      }

      cells.push(<td key="price" className="price">{row.price}</td>);
    }

    rows.push(<tr key={"row" + i} className={rowClassName}>{cells}</tr>);

    // Special case for descriptions
    if (row.description)
      rows.push(<tr key={"row" + i + "description"}><td className={rowClassName + " description"} colSpan={colSpan}>{row.description}</td></tr>);
  }

  let tbody = (rows.length ? <tbody>{rows}</tbody> : null);

  return (
    <table className="menuTable">
      {thead}
      {tbody}
    </table>
  )
}

function capitalize(item) {
  return item.charAt(0).toUpperCase() + item.slice(1);
}
