import React from 'react'

const Menu = props => (
	<div className="menuWrapper">
		<div className="chevron tl" />
		<div className="chevron tr" />
		<div className="chevron bl" />
		<div className="chevron br" />
		{props.children}
	</div>
)

export default Menu