// Recursively look at menuItems. Return true if any sub-item has a location matching the given location
export function locationHasMenuItem(location, menuItem)
{
	if (menuItem === null || menuItem === false)
		return false;

	if (Array.isArray(menuItem))
	{
		for (let item of menuItem)
		{
			if (locationHasMenuItem(location, item))
				return true;
		}
	}
	else if (typeof menuItem === 'object')
	{
		// Look at all the keys inside menuItem.
		// If any of them are "location", check it against location
		// Return true if we find one, or false if we cannot
		const keys = Object.keys(menuItem);
		for (let key of keys)
		{
			// Check location!
			if (key === 'locations' && menuItem[key].includes(location))
				return true;

			// Okay, check child keys then
			if (locationHasMenuItem(location, menuItem[key]))
				return true;
		}
	}

	// If we got here, we definitely want to return false
	return false;
}