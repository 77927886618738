export const locationData =
{
	locations: [
		{
			id: "bryant",
			name: "33rd & Bryant",
			// byline: "Open until 9pm",
			address: {
				street: "3324 S Bryant Avenue",
				street2: "Suite 130",
				city: "Edmond",
				state: "OK",
				zip: "73013",
			},
			phone: "405-237-3005",
			hours: [
				{ day: "Monday", hours: "7:00 AM - 9:00 PM" },
				{ day: "Tuesday", hours: "7:00 AM - 9:00 PM" },
				{ day: "Wednesday", hours: "7:00 AM - 9:00 PM" },
				{ day: "Thursday", hours: "7:00 AM - 9:00 PM" },
				{ day: "Friday", hours: "7:00 AM - 9:00 PM" },
				{ day: "Saturday", hours: "7:00 AM - 9:00 PM" },
				{ day: "Sunday", hours: "8:00 AM - 1:00 PM" },
			],
			picture: "/images/locations/location_bryant.jpg",
			mapEmbedURL: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.200471545899!2d-97.46210068432877!3d35.622784080208035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87b21fb648cd607f%3A0xfce32274809c0358!2sEllis%20Island%20Coffee%20and%20Wine%20Lounge!5e0!3m2!1sen!2sus!4v1601009814313!5m2!1sen!2sus"
		},
		{
			id: "downtown",
			name: "Downtown Edmond",
			// byline: "Open until 8pm",
			address: {
				street: "130 N Broadway",
				street2: "Suite 150",
				city: "Edmond",
				state: "OK",
				zip: "73034",
			},
			phone: "405-726-8831",
			hours: [
				{ day: "Monday", hours: "7:00 AM - 8:00 PM" },
				{ day: "Tuesday", hours: "7:00 AM - 8:00 PM" },
				{ day: "Wednesday", hours: "7:00 AM - 8:00 PM" },
				{ day: "Thursday", hours: "7:00 AM - 8:00 PM" },
				{ day: "Friday", hours: "7:00 AM - 8:00 PM" },
				{ day: "Saturday", hours: "7:00 AM - 8:00 PM" },
				{ day: "Sunday", hours: "8:00 AM - 1:00 PM" },
			],
			picture: "/images/locations/location_downtown.jpg",
			mapEmbedURL: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.8031137590388!2d-97.48302198432808!3d35.657222180199796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87b21fb4c89af8ab%3A0x9cfa02a5b03976c0!2sEllis%20Island%20Coffee%20and%20Wine%20Lounge!5e0!3m2!1sen!2sus!4v1601010042984!5m2!1sen!2sus"
		},
	],

	getLocationData: function(locationId)
	{
		for (const location of this.locations)
		{
			if (locationId === location.id)
				return location;
		}

		return null;
	},
}
