import React from 'react'
import Layout from '../../components/layout'
import EllisHelmet from '../../components/EllisHelmet'
import LocationMenu from '../../components/location-specific/LocationMenu'
import OtherDrinksPage from '../../components/location-specific/OtherDrinksPage'

class OtherDrinksBryant extends React.Component
{
	render()
	{
		const location = "bryant";
		const currentPage = "otherdrinks";
		const title = "Not Coffee";

		return (
			<Layout className="menuPage" location={location} currentPage={currentPage}>
				<EllisHelmet page={currentPage} title={title} location={location} />
				<LocationMenu location={location} currentPage={currentPage} />
				<OtherDrinksPage location={location} />
			</Layout>
		)
	}
}

export default OtherDrinksBryant